<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Assume that
        <number-value :value="molA" unit="\text{M}" />
        of
        <chemical-latex content="A(g)" />
        and
        <number-value :value="molB" unit="\text{M}" />
        of
        <chemical-latex content="B(g)" />
        are sealed in a reaction vessel and undergo the reaction shown below:
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="A(g) + 3 B(g) <=> 2 C(g)" />
      </p>

      <p class="mb-2">
        a) Based on the information provided above ONLY, fill in the ICE table below inputting
        <stemble-latex content="$\mathrm{x}$" />
        for any relative changes. Be sure to indicate the direction of change, i.e. +/-. Be sure to
        use the proper number of significant figures. Any zeroes should be denoted as 0. The
        equilibrium row should still contain the
        <stemble-latex content="$\mathrm{x}$" />
        variable.
      </p>

      <v-simple-table class="mb-5" style="width: 550px" dense>
        <thead>
          <tr>
            <th style="vertical-align: middle; text-align: center; font-size: 15px" />
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="A(g)" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <stemble-latex content="$+$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="3 B(g)" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="<=>" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="2 C(g)" />
            </th>
          </tr>

          <tr>
            <td style="vertical-align: middle; height: 60px; text-align: center">
              <stemble-latex content="$\textbf{I}$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.initialA" class="centered-input" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.initialB" class="centered-input" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.initialC" class="centered-input" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle; height: 60px; text-align: center">
              <stemble-latex content="$\textbf{C}$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.changeA" class="centered-input" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.changeB" class="centered-input" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.changeC" class="centered-input" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle; height: 60px; text-align: center">
              <stemble-latex content="$\textbf{E}$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.equilibriumA" class="centered-input" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.equilibriumB" class="centered-input" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.equilibriumC" class="centered-input" />
            </td>
          </tr>
        </thead>
      </v-simple-table>

      <p class="mb-2">
        b) Determine the value of
        <stemble-latex content="$\text{x,}$" />
        if there is
        <number-value :value="molAeq" unit="\text{M}" />
        of
        <chemical-latex content="A(g)" />
        remaining once the reaction reaches equilibrium.
      </p>

      <calculation-input
        v-model="inputs.valueOfx"
        class="mb-5"
        prepend-text="$\text{x:}$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) Based on parts a) and b), determine the value of the equilibrium constant,
        <stemble-latex content="$\text{K}_\text{c},$" />
        for the reaction.
      </p>

      <calculation-input
        v-model="inputs.Kc"
        prepend-text="$\text{K}_\text{c}:$"
        :disabled="!allowEditing"
      />

      <!--            TODO: Maybe add a calculation for /\n and Kp for this? -->
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import IceInput from '../inputs/IceInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question157',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    IceInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        initialA: null,
        initialB: null,
        initialC: null,
        changeA: null,
        changeB: null,
        changeC: null,
        equilibriumA: null,
        equilibriumB: null,
        equilibriumC: null,
        valueOfx: null,
        Kc: null,
      },
    };
  },
  computed: {
    molA() {
      return this.taskState.getValueBySymbol('molA').content;
    },
    molB() {
      return this.taskState.getValueBySymbol('molB').content;
    },
    molAeq() {
      return this.taskState.getValueBySymbol('molAeq').content;
    },
  },
};
</script>

<style>
.centered-input input {
  text-align: center;
}
</style>
